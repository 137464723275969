import { init, setUserId, track, Types } from "@amplitude/analytics-browser"

import { amplitudeApiKey } from "config"

import { AmplitudeEvent } from "./events"

// depending on the env this can be true or false
const useAmplitude = !!amplitudeApiKey

export const amplitudeInit = () => {
  init(amplitudeApiKey, {
    defaultTracking: false,
    logLevel: Types.LogLevel.Error,
    serverZone: "EU"
  })
}

export const setAmplitudeUserId = (userId: string) => {
  setUserId(userId)
}

export const amplitudeTrack = <T extends AmplitudeEvent>({ type, eventProperties }: T) => {
  if (useAmplitude) {
    track(type, eventProperties)
  }
}
