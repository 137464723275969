import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"

import { Button, ICONS, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import DateInput from "components/DateInput/DateInput"
import OnlineStatus from "components/OnlineStatus/OnlineStatus"
import TimeInput from "components/TimeInput/TimeInput"

import styles from "./PostponeToStaff.module.scss"

interface PostponeToStaffProps {
  resetSearch: () => void
  selectedStaff: Staff
  time: string
  setTime: (time: string) => void
  date: string
  setDate: (date: string) => void
  isNowSelected: boolean
  onSend: () => void
  closeModal: () => void
}

const PostponeToStaff = ({
  resetSearch,
  selectedStaff,
  time,
  setTime,
  date,
  setDate,
  isNowSelected,
  onSend,
  closeModal
}: PostponeToStaffProps) => {
  const intl = useIntl()
  const [isSendDisabled, setSendDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (isNowSelected) return
    if (!time || !date) setSendDisabled(true)
    if (time && date) setSendDisabled(false)
    if (dayjs(`${date} ${time}`).diff(dayjs()) <= 0) setSendDisabled(true)
  }, [isNowSelected, time, date])

  return (
    <>
      <div className={styles.upperContainer}>
        <button className={styles.resetSearchButton} onClick={resetSearch}>
          <div className={styles.staff}>
            <StaffLabelTextXS as="span">{selectedStaff?.displayName}</StaffLabelTextXS>
            <OnlineStatus online={selectedStaff?.online!} className={styles.onlineStatus} />
          </div>
          <div className={styles.resetIcons}>
            <ICONS.CancelFilled />
            <ICONS.ChevronDown className={styles.searchIcon} />
          </div>
        </button>
      </div>

      <div className={styles.bottomContainer}>
        <StaffLabelTextXS as="h3">{intl.formatMessage({ id: "postpone.title" })}</StaffLabelTextXS>
        <div className={styles.row}>
          <Button
            variant={isNowSelected ? "primary" : "outline"}
            color="accent"
            onPress={() => {
              setTime("")
              setDate("")
              setSendDisabled(false)
            }}>
            {intl.formatMessage({ id: "postpone.now" })}
          </Button>
        </div>
        <div className={styles.row}>
          <TimeInput darkMode={!!time} value={time} onChange={setTime} />
          <DateInput darkMode={!!date} value={date} onChange={setDate} />
        </div>

        <div className={styles.row}>
          <Button variant="secondary" color="primary" onPress={closeModal} fullWidth>
            {intl.formatMessage({ id: "btn.cancel" })}
          </Button>
          <Button variant="primary" color="primary" onPress={onSend} isDisabled={isSendDisabled} fullWidth>
            {intl.formatMessage({ id: "label.send" })}
          </Button>
        </div>
      </div>
    </>
  )
}

export default PostponeToStaff
