import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import Select, { components, ControlProps, DropdownIndicatorProps, OptionProps } from "react-select"

import { ICONS, StaffBodyTextMStyle } from "@doktor-se/bones-ui"

import CodeRow from "./CodeRow"
import { removeFromFavorites, retrieveFromLocalStorage, saveToFavorites } from "./codesHelpers"

import styles from "./SelectCodes.module.scss"

export interface DiagnosisCode {
  value: string
  label: string
  isFavorite?: boolean
}

const kvaCodes: DiagnosisCode[] = [
  {
    value: "276.0",
    label: "276.0 Treatment 1"
  },
  {
    value: "269.0",
    label: "269.0 Treatment 23"
  },
  { value: "213.7", label: "213.7 Treatment 3" },
  { value: "123.0", label: "124 Treatment 4" }
]

// react-select overrides
const DropdownIndicator = (props: DropdownIndicatorProps<DiagnosisCode, true>) => {
  const { selectProps } = props
  const isOpen = selectProps.menuIsOpen

  return (
    <components.DropdownIndicator {...props} className={styles.dropdownArrow}>
      <div>{isOpen ? <ICONS.ChevronUp /> : <ICONS.ChevronDown />}</div>
    </components.DropdownIndicator>
  )
}

const Control = (props: ControlProps<DiagnosisCode, true>) => (
  <components.Control {...props}>
    {props.selectProps.menuIsOpen && <ICONS.Search />}
    {props.children}
  </components.Control>
)

const Option = (props: OptionProps<DiagnosisCode>) => {
  return (
    <components.Option {...props} className={styles.option}>
      {props.children}
      {props.data.isFavorite && <ICONS.Star className={styles.starIcon} />}
    </components.Option>
  )
}

interface SelectCodesProps {
  codesValue?: string[]
  onCodesChange: (values: string[]) => void
}

const SelectCodes = ({ codesValue, onCodesChange }: SelectCodesProps) => {
  const intl = useIntl()
  const [favorites, setFavorites] = useState<DiagnosisCode[]>(() => retrieveFromLocalStorage())
  const [selectedCodes, setSelectedCodes] = useState<DiagnosisCode[]>([])

  useEffect(() => {
    if (codesValue && codesValue.length) {
      const codes = kvaCodes.filter(c => codesValue.includes(c.value))
      setSelectedCodes(codes)
    }
  }, [codesValue])

  const selectedCodesChange = (codes: DiagnosisCode[]) => {
    const values = codes.map(c => c.value)
    onCodesChange(values)
  }

  const onCodeSelect = (codes: readonly DiagnosisCode[]) => {
    setSelectedCodes([...codes])
    selectedCodesChange([...codes])
  }

  const toggleFavorite = (code: DiagnosisCode, favorite: boolean) => {
    if (favorite) {
      setFavorites(removeFromFavorites(code))
    } else {
      setFavorites(saveToFavorites(code))
    }
  }

  const onCodeRemove = (code: DiagnosisCode) => {
    const codes = [...selectedCodes.filter(c => c.value !== code.value)]
    setSelectedCodes([...codes])
    selectedCodesChange([...codes])
  }

  const codes = useMemo(() => {
    const grouped = []
    // favorite codes
    grouped.push({ label: "", options: favorites.map(c => ({ ...c, isFavorite: true })) })
    // rest of the codes
    const options = kvaCodes
      .filter(c => selectedCodes.filter(s => s.value !== c.value))
      .filter(c => !favorites.find(f => f.value === c.value))
    grouped.push({ label: "", options })
    return grouped
  }, [favorites, selectedCodes])

  return (
    <div className={styles.selectCodesWrapper}>
      <Select
        closeMenuOnSelect={false}
        defaultValue={selectedCodes}
        options={codes}
        isClearable={false}
        isMulti
        placeholder={intl.formatMessage({ id: "journal.webdoc.codes.placeholder" })}
        controlShouldRenderValue={false}
        isSearchable
        onChange={onCodeSelect}
        value={selectedCodes}
        styles={{
          control: (base, state) => ({
            ...base,
            background: state.menuIsOpen ? "var(--color-surface)" : "var(--color-primary-10)",
            borderColor: "var(--color-primary-10)",
            borderRadius: "4px",
            paddingLeft: state.menuIsOpen ? "var(--size-100)" : "",
            "&:hover": {
              borderColor: "var(--color-primary-10)"
            },
            fontFamily: "var(--regular-text-body-m-font-family), Arial, sans-serif",
            fontSize: "var(--regular-text-body-m-font-size)",
            fontWeight: "var(--regular-text-body-m-font-weight)",
            lineHeight: "var(--regular-text-body-m-line-height)",
            textDecoration: "var(--regular-text-body-m-text-decoration)",
            letterSpacing: "var(--regular-text-body-m-letter-spacing)"
          }),
          menu: base => ({
            ...base,
            background: "var(--color-primary-10)",
            padding: "0 12px"
          }),
          group: base => ({
            ...base,
            "&:not(:last-child)": {
              paddingBottom: "var(--size-100)",
              borderBottom: "1px solid #9EACE9"
            }
          }),
          option: base => ({
            ...base,
            ...StaffBodyTextMStyle
          }),
          indicatorsContainer: base => ({
            ...base,
            backgroundColor: "var(--color-primary-10)"
          })
        }}
        components={{ DropdownIndicator, IndicatorSeparator: null, Control, GroupHeading: () => <></>, Option }}
      />
      {selectedCodes.map(s => (
        <CodeRow
          code={s}
          favorite={!!favorites.find(f => f.value === s.value)}
          onCodeRemove={onCodeRemove}
          toggleFavorite={toggleFavorite}
        />
      ))}
    </div>
  )
}

export default SelectCodes
