import { useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"
import isoWeek from "dayjs/plugin/isoWeek"
import timezone from "dayjs/plugin/timezone"

import { Dialog, Radio, RadioGroup, StaffHeading3 } from "@doktor-se/bones-ui"
import { AssignedConversation, CallEndReason, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { assignToQueue } from "api"
import { amplitudeTrack } from "lib/amplitude/amplitude"
import { AMPLITUDE_EVENT_TYPE, AmplitudeEvent } from "lib/amplitude/events"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"
import { SearchPageConversation } from "types"

import { ToDigitalQueue } from "./components/ToDigitalQueue"
import ToPhysicalQueue from "./components/ToPhysicalQueue"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import styles from "./PhysicalToDigitalDialog.module.scss"

dayjs.extend(timezone)
dayjs.extend(isoWeek)

export type Day = "today" | "tomorrow" | undefined

export interface ConfirmParams {
  postponed?: string
  clinicId?: string
}

type DialogType = "digital" | "physical"
const dialogOptions = [
  {
    value: "digital",
    translationKey: "control.unassign.option.digital"
  },
  {
    value: "physical",
    translationKey: "control.unassign.option.physical"
  }
]

const amplitudeLookupTable: Record<string, AMPLITUDE_EVENT_TYPE> = {
  "digital-digital": "conversation.to_queue.digital_to_digital",
  "digital-physical": "conversation.to_queue.digital_to_physical",
  "physical-digital": "conversation.to_queue.physical_to_digital",
  "physical-physical": "conversation.to_queue.physical_to_physical"
}

export interface PhysicalToDigitalDialogProps {
  isOpen: boolean
  onClose: (refetch?: boolean) => void
  offset?: Offset
  conversation: AssignedConversation | SearchPageConversation
}

const PhysicalToDigitalDialog = ({ isOpen, offset, onClose, conversation }: PhysicalToDigitalDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const clinics = useAppSelector(clinicSelectors.selectAll)
  const user = useAppSelector(state => state.auth.user)
  const userClinic = !!user?.data?.clinic

  const [dialogOption, setDialogOption] = useState<DialogType>("digital")

  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>()

  const logAmplitude = (clinicId?: string) => {
    // determine from direction, if user has clinic we assume its physical conversation
    const from = userClinic ? "physical" : "digital"
    // determine to, which is easy as we have a radio button to find out
    const to = dialogOption

    const key = `${from}-${to}`

    const eventType = amplitudeLookupTable[key]

    const clinic = clinics.find(c => c.id === clinicId)

    const event = {
      type: eventType,
      eventProperties: {
        conversation_id: conversation.id,
        queue: selectedQueue!.queueKey,
        ...(clinic && { clinic_id: clinic?.id, clinic_name: clinic?.name })
      }
    } as AmplitudeEvent

    amplitudeTrack(event)
  }

  const onCloseDialog = (refetch?: boolean) => {
    onClose(refetch)
  }

  const handleDialogOptionChange = (dialogType: DialogType) => {
    setDialogOption(dialogType)
    setSelectedQueue(undefined)
  }

  const unassignConfirmed = (params: ConfirmParams) => {
    if (!selectedQueue) return
    let reason: CallEndReason | undefined
    if (conversation.callState !== "incoming") reason = checkDeclineCall(conversation)
    dispatch(assignToQueue(conversation, selectedQueue?.queueKey, params.postponed, reason, params.clinicId))
    logAmplitude(params.clinicId)
    onCloseDialog(false)
  }

  return (
    <Dialog
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <>
        <StaffHeading3 className={styles.heading}>{intl.formatMessage({ id: "unassign.title" })}</StaffHeading3>
        <div className={styles.radioWrapper}>
          <RadioGroup
            label=""
            aria-label="Dialog Type"
            aria-labelledby=""
            defaultValue="digital"
            onChange={value => {
              handleDialogOptionChange(value as DialogType)
            }}>
            {dialogOptions.map(option => (
              <Radio key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.translationKey })}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {dialogOption === "digital" && (
          <ToDigitalQueue
            conversationId={conversation.id}
            selectedQueue={selectedQueue}
            setSelectedQueue={setSelectedQueue}
            onSend={unassignConfirmed}
          />
        )}
        {dialogOption === "physical" && (
          <ToPhysicalQueue
            conversation={conversation}
            selectedQueue={selectedQueue}
            setSelectedQueue={setSelectedQueue}
            onSend={unassignConfirmed}
          />
        )}
      </>
    </Dialog>
  )
}

export default PhysicalToDigitalDialog
