import { useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"
import { debounce } from "lodash"

import { Button, CopyToClipboard, ICONS, TextArea } from "@doktor-se/bones-ui"
import { JournalSection } from "@doktor-se/bones-ui/dist/web-shared/types"

import SelectCodes from "./components/codes/SelectCodes"
import ConversationImages from "./components/images/ConversationImages"

import { saveJournalNote, sendToWebdocCall } from "../../../../../../api"
import { featureFlags } from "../../../../../../config"
import { amplitudeTrack } from "../../../../../../lib/amplitude/amplitude"
import { useAppDispatch, useAppSelector } from "../../../../../../lib/hooks"
import { selectConversation } from "../../../../../../reducers/conversations/conversations.reducer"
import { updateJournalDraftWebdoc } from "../../../../../../reducers/selected"

import styles from "./JournalForm.module.scss"

const JournalForm = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const currentStaff = useAppSelector(state => state.auth.user)
  const conversation = useAppSelector(selectConversation)

  const codesEqual = (codesArr1: string[], codesArr2: string[]): boolean => {
    if (codesArr1.length !== codesArr2.length) {
      return false
    }

    const sortCodesArray = (arr: string[]) => [...arr].sort((a, b) => parseFloat(a) - parseFloat(b))

    const sortedArray1 = sortCodesArray(codesArr1)
    const sortedArray2 = sortCodesArray(codesArr2)

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false
      }
    }

    return true
  }

  const sectionsEquals = (sections1: JournalSection[], sections2: JournalSection[]): boolean => {
    //const savedSections = conversation?.journalNote?.data.sections
    if (sections1.length !== sections2?.length) {
      return false
    }

    const sortList = (list: JournalSection[]): JournalSection[] => {
      return list.slice().sort((a, b) => {
        if (a.title !== b.title) {
          return a.title.localeCompare(b.title)
        }
        return a.body.localeCompare(b.body)
      })
    }
    const sortedList1 = sortList(sections1)
    const sortedList2 = sortList(sections2)
    for (let i = 0; i < sortedList1.length; i++) {
      if (sortedList1[i].title !== sortedList2[i].title || sortedList1[i].body !== sortedList2[i].body) {
        return false
      }
    }
    return true
  }

  const { sections: currentJournalNoteSections, codes: currentDiagnosisCodes = [] } = useAppSelector(
    state =>
      state.selected.localConversationStates
        ?.filter(item => item.conversationId === conversation?.id)
        .map(item => item.state.journalDraft?.data)
        .pop() ||
      conversation?.journalNote?.data || { sections: [], codes: [] }
  )

  const [hasSaved, setHasSaved] = useState(false)

  const debouncedSaveRef = useRef<({ sections, codes }: { sections: JournalSection[]; codes: string[] }) => void>()

  useEffect(() => {
    if (!debouncedSaveRef.current) {
      debouncedSaveRef.current = debounce(({ sections, codes }: { sections: JournalSection[]; codes: string[] }) => {
        if (
          !hasSaved &&
          conversation?.journalNote?.data.sections &&
          (!sectionsEquals(conversation?.journalNote?.data.sections, sections) ||
            !codesEqual(conversation?.journalNote?.data.codes || [], codes))
        ) {
          conversation && dispatch(saveJournalNote(conversation.id, sections, codes))
          setHasSaved(true)
        }
      }, 10000)
    }
  }, [dispatch, conversation, currentJournalNoteSections, hasSaved])

  useEffect(() => {
    if (hasSaved) {
      const timer = setTimeout(() => {
        setHasSaved(false)
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [hasSaved])

  const onCopy = () => {
    amplitudeTrack({
      type: "carealot.chat_journal.copy_note_pressed",
      eventProperties: {
        category_id: conversation?.categoryId
      }
    })
  }
  const updateJournalNoteId = useCallback(
    (journalNoteId: string) => {
      const journalNoteDraft = conversation?.journalNote && {
        ...conversation.journalNote,
        data: {
          ...conversation.journalNote.data,
          journalNoteId: journalNoteId
        }
      }
      journalNoteDraft && dispatch(updateJournalDraftWebdoc(journalNoteDraft))
    },
    [conversation?.journalNote, dispatch]
  )
  const updateValue = useCallback(
    (key: string, value: string) => {
      const journalNoteDraft = conversation?.journalNote && {
        ...conversation?.journalNote,
        data: {
          sections: currentJournalNoteSections.map(section =>
            section.title === key ? { ...section, body: value } : section
          )
        }
      }
      journalNoteDraft && dispatch(updateJournalDraftWebdoc(journalNoteDraft))
    },
    [conversation?.journalNote, currentJournalNoteSections, dispatch]
  )

  const updateCodesValue = useCallback(
    (codes: string[]) => {
      const journalNoteDraft = conversation?.journalNote && {
        ...conversation?.journalNote,
        data: {
          ...conversation?.journalNote.data,
          codes
        }
      }
      journalNoteDraft && dispatch(updateJournalDraftWebdoc(journalNoteDraft))
    },
    [conversation?.journalNote, dispatch]
  )

  useEffect(() => {
    debouncedSaveRef.current!({ sections: currentJournalNoteSections, codes: currentDiagnosisCodes })
  }, [currentDiagnosisCodes, currentJournalNoteSections])

  const sendToWebdoc = () => {
    dispatch(sendToWebdocCall(currentJournalNoteSections, currentStaff?.pnr, conversation?.account?.pnr)).then(
      result => {
        updateJournalNoteId(result)
      }
    )
  }
  useEffect(() => {
    conversation?.journalNote && updateJournalDraftWebdoc(conversation?.journalNote)
  }, [conversation, hasSaved])

  return (
    <>
      <div className={styles.container}>
        {!codesEqual(currentDiagnosisCodes, conversation?.journalNote?.data.codes || []) && (
          <div className={classNames([styles.edited, styles.editedCodes])}>
            {intl.formatMessage({ id: "journal.edited.indicator" })}
          </div>
        )}
        <SelectCodes codesValue={currentDiagnosisCodes} onCodesChange={updateCodesValue} />
        {intl.formatMessage({ id: "journal.header" })}
        {currentJournalNoteSections.map((item, index) => (
          <div className={styles.journalSection}>
            <div className={styles.header}>
              <div key={item.title + index} className={styles.sectionTitle}>
                {intl.formatMessage({ id: item.title })}
              </div>
              {item.body !==
              conversation?.journalNote?.data.sections.find(section => item.title === section.title)?.body ? (
                <div key={item.title + "_i_" + index} className={styles.edited}>
                  {intl.formatMessage({ id: "journal.edited.indicator" })}
                </div>
              ) : undefined}
              <CopyToClipboard
                onCopy={onCopy}
                copyText={item.body || ""}
                confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
              />
            </div>
            <div className={styles.sectionTextArea}>
              <TextArea
                variant={"onSurface"}
                noVisualLabel={true}
                label={"nolabel"}
                noBorder
                fullWidth={true}
                onChange={(value: string) => updateValue(item.title, value)}
                value={item.body}
              />
            </div>
          </div>
        ))}
        <div className={styles.actionButtons}>
          {featureFlags.has("webdoc_journal_note_send") && (
            <Button variant="primary" color="primary" onPress={sendToWebdoc} isDisabled={false}>
              Send to Webdoc
            </Button>
          )}
        </div>
        {conversation && <ConversationImages conversation={conversation} />}
        <div className={styles.copyAllContainer}>
          <div className={styles.infoText}>
            <div className={styles.warningIcon}>
              <ICONS.WarningCircle height={20} width={20} />
            </div>
            <div className={styles.warningText}>{intl.formatMessage({ id: "journal.copy.warning" })}</div>
          </div>
          <div className={styles.copyAllButton}>
            <div className={styles.copyAllText}>Kopiera allt</div>
            <CopyToClipboard
              onCopy={onCopy}
              copyText={currentJournalNoteSections
                .map(section => `${intl.formatMessage({ id: section.title })}\n${section.body}`)
                .join("\n")}
              confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default JournalForm
