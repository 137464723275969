import { IntlShape } from "react-intl"

import { TextsCarealot } from "@doktor-se/bones-ui/dist/web-shared/components"
import { LanguageCode, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

export const feedTexts = (intl: IntlShape, queues?: Queue[], appLanguage?: LanguageCode): TextsCarealot => ({
  conversationState: {
    opened: intl.formatMessage({ id: "label.opened" }),
    closed: intl.formatMessage({ id: "label.closed" }),
    created: intl.formatMessage({ id: "label.created" })
  },
  messageActionButtons: {
    copy: intl.formatMessage({ id: "message.action.copy" }),
    download: intl.formatMessage({ id: "message.action.download" }),
    rotate: intl.formatMessage({ id: "message.action.rotate" }),
    upload: intl.formatMessage({ id: "message.action.upload" }),
    open: intl.formatMessage({ id: "message.action.open" }),
    delete: intl.formatMessage({ id: "message.action.delete" }),
    webdocUpload: (data: { name?: string; pnr?: string }) => intl.formatMessage({ id: "confirm.webdoc.upload" }, data),
    buttonShow: intl.formatMessage({ id: "message.action.show" }),
    buttonSend: intl.formatMessage({ id: "label.send" }),
    buttonCancel: intl.formatMessage({ id: "btn.cancel" })
  },
  formatDateAndTime: (date: string) =>
    `${intl.formatDate(date, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })} ${intl.formatTime(date)}`,
  profile: {
    profileRemoved: (data: { name?: string }) => intl.formatMessage({ id: "feed.profile.removed" }, data),
    profileNotFound: intl.formatMessage({ id: "feed.profile.not.found" }),
    profileChangedToRemovedProfile: intl.formatMessage({ id: "feed.profile.change.removed" }),
    profileChanged: (name: string, profileType: string) =>
      intl.formatMessage(
        { id: "feed.profile.change" },
        { name, type: intl.formatMessage({ id: `profile.type.${profileType}` }) }
      )
  },
  assignment: {
    assignedToQueue: (queueKey?: string | null) => {
      const specificQueue = queues!.find(queue => queue.queueKey === queueKey)?.translations[appLanguage!]
      if (specificQueue) return intl.formatMessage({ id: "feed.assignment.queue.specific" }, { queue: specificQueue })
      return intl.formatMessage({ id: "feed.assignment.queue" })
    },
    assignmentMessage: (data: { prev: string; next: string }) => intl.formatMessage({ id: "feed.assignment" }, data),
    unknown: intl.formatMessage({ id: "label.unknown" })
  },
  call: {
    callMessage: (id?: string) => intl.formatMessage({ id }),
    callDuration: (data: { time: string }) => intl.formatMessage({ id: "feed.call.duration" }, data)
  },
  callStateMessages: {
    incomingCall: intl.formatMessage({ id: "calls.callingin" }),
    outgoingCall: intl.formatMessage({ id: "calls.calling" }),
    answerCall: intl.formatMessage({ id: "calls.answer" }),
    declineCall: intl.formatMessage({ id: "calls.decline" })
  },
  snooze: {
    snoozed: intl.formatMessage({ id: "label.snoozed" }),
    toInbox: intl.formatMessage({ id: "action.inbox" })
  },
  feedFooter: {
    commentTitle: intl.formatMessage({ id: "feed.footer.comment.title" }),
    payment: intl.formatMessage({ id: "feed.footer.payment.label" }),
    enterSend: intl.formatMessage({ id: "feed.footer.enter" }),
    comment: intl.formatMessage({ id: "feed.footer.comment" }),
    phrasesSearchEmpty: intl.formatMessage({ id: "phrases.search.empty" }),
    copyConfirmText: intl.formatMessage({ id: "copied.confirmation" }),
    closeAriaLabel: intl.formatMessage({ id: "modal.close" }),
    encryptionEnabled: intl.formatMessage({ id: "encryption.conversation.encrypted" }),
    newMessagesBanner: intl.formatMessage({ id: "feed.new_messages.label" }),
    phrases: intl.formatMessage({ id: "feed.footer.phrases" }),
    uploadFile: intl.formatMessage({ id: "feed.footer.upload_file" })
  },
  phrases: {
    phrasesDropdownDigitalCare: intl.formatMessage({ id: "label.phrases.digital_care" }),
    phrasesDropdownPhysicalCare: intl.formatMessage({ id: "label.phrases.physical_care" }),
    phrasesHeader: intl.formatMessage({ id: "label.phrases.header" })
  },
  flag: {
    flagConversationReportTitle: intl.formatMessage({ id: "flag.report.title" }),
    flagConversationReportButton: intl.formatMessage({ id: "flag.report.button" }),
    closeAriaLabel: intl.formatMessage({ id: "modal.close" }),
    flagSubjectLabel: intl.formatMessage({ id: "flag.subject.label" }),
    flagSubjectPlaceholder: intl.formatMessage({ id: "flag.subject.placeholder" }),
    flagBodyLabel: intl.formatMessage({ id: "flag.body.label" }),
    flagBodyPlaceholder: intl.formatMessage({ id: "flag.body.placeholder" }),
    flagSendReport: intl.formatMessage({ id: "flag.send.report" }),
    flagPopupHeader: intl.formatMessage({ id: "flag.popup.header" }),
    flagPopupMessage: intl.formatMessage({ id: "flag.popup.message" }),
    flagPopupButton: intl.formatMessage({ id: "flag.popup.button" })
  },
  clinicTag: {
    noClinic: intl.formatMessage({ id: "tag.clinic.none" }),
    assignToNotListedClinic: intl.formatMessage({ id: "tag.clinic.assign" }),
    closeDialog: intl.formatMessage({ id: "modal.close" }),
    confirm: intl.formatMessage({ id: "label.yes" }),
    cancel: intl.formatMessage({ id: "btn.cancel" })
  },
  actionButtonSent: (data: { title: string }) => intl.formatMessage({ id: "action.button.sent" }, data),
  staffComment: (data: { name: string }) => intl.formatMessage({ id: "feed.comment.profile" }, data),
  categoryChanged: (data: { from: string; to: string }) => intl.formatMessage({ id: "feed.category.change" }, data),
  emptyChatBotForm: intl.formatMessage({ id: "form.empty" }),
  encryption: {
    consentAwaiting: intl.formatMessage({ id: "encryption.consent.awaiting" }),
    consentDenied: intl.formatMessage({ id: "encryption.consent.denied" }),
    consentGranted: intl.formatMessage({ id: "encryption.consent.granted" })
  }
})
