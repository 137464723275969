import dayjs from "dayjs"

import { fetchAssignedConversation } from "api/conversations/conversations.api"
import { featureFlags } from "config"
import { Thunk } from "lib/hooks"
import { removeSnooze, setSnoozeTimeout } from "reducers/conversations/conversations.reducer"

export const snoozeTimer =
  (conversationId: string, snoozedUntil?: string): Thunk =>
  dispatch => {
    const timeout = setTimeout(() => {
      dispatch(removeSnooze(conversationId))
      if (featureFlags.has("conversations_load_split") && conversationId) {
        dispatch(fetchAssignedConversation(conversationId))
      }
    }, dayjs(snoozedUntil).diff(dayjs()))
    dispatch(setSnoozeTimeout({ timeout, data: { conversationId, snoozedUntil } }))
  }
