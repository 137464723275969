import { useIntl } from "react-intl"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { Button, Dropdown, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { partnerConfig } from "@doktor-se/bones-ui/dist/web-shared/config"

import { featureFlags, platform } from "config"

import { PetProfileData } from "../types"
import ControlledTextInput from "./ControlledTextInput"

import styles from "./AddPet.module.scss"

interface AddPetProps {
  addProfile: (data: PetProfileData) => void
  error?: AppError
  close: () => void
}

const AddPet = ({ addProfile, error, close }: AddPetProps): JSX.Element => {
  const intl = useIntl()

  const insuranceCompanyOptions = [
    { id: "none", name: intl.formatMessage({ id: "label.insurance.none" }) },
    { id: "Agria", name: "Agria" },
    { id: "Folksam", name: "Folksam" },
    { id: "Ica försäkring", name: "Ica försäkring" },
    { id: "Dina försäkringar", name: "Dina försäkringar" },
    { id: "Svedea", name: "Svedea" },
    { id: "If", name: "If" },
    { id: "Moderna", name: "Moderna" },
    { id: "Sveland", name: "Sveland" },
    { id: "other", name: intl.formatMessage({ id: "label.insurance.other" }) }
  ]

  const sexOptions = [
    { id: "female" as const, name: intl.formatMessage({ id: "sex.female" }) },
    { id: "female.castrated" as const, name: intl.formatMessage({ id: "sex.female.castrated" }) },
    { id: "male" as const, name: intl.formatMessage({ id: "sex.male" }) },
    { id: "male.castrated" as const, name: intl.formatMessage({ id: "sex.male.castrated" }) },
    { id: "unknown" as const, name: intl.formatMessage({ id: "label.unknown" }) }
  ]

  const speciesOptions = [
    { id: "cat" as const, name: intl.formatMessage({ id: "species.cat" }) },
    { id: "dog" as const, name: intl.formatMessage({ id: "species.dog" }) }
  ]

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          name: "",
          breed: "",
          species: speciesOptions[0].id,
          sex: sexOptions[0].id,
          dateOfBirth: "",
          insuranceCompany: insuranceCompanyOptions[0].id,
          insuranceNumber: "",
          icon: ""
        }}
        onSubmit={values => {
          addProfile({
            name: values.name,
            breed: values.breed,
            species: values.species,
            sex: values.sex,
            date_of_birth: values.dateOfBirth,
            insurance_company: values.insuranceCompany,
            insurance_number: values.insuranceNumber,
            icon: `pets/${values.species}s/${values.species}_1`,
            type: "pet"
          })
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(intl.formatMessage({ id: "error.required" })),
          breed: Yup.string().required(intl.formatMessage({ id: "error.required" })),
          dateOfBirth: Yup.string().required(intl.formatMessage({ id: "error.required" }))
        })}>
        {({ setFieldValue, values }) => (
          <Form>
            <StaffLabelTextXS>{intl.formatMessage({ id: "profile.add" })}</StaffLabelTextXS>

            <div className={styles.inputs}>
              <ControlledTextInput
                name="name"
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.name" })}
                onChange={(value: string) => setFieldValue("name", value)}
              />

              <Dropdown
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.species" })}
                labelPosition="top"
                items={speciesOptions}
                onSelectionChange={key => setFieldValue("species", key)}
                selectedKey={values.species}
                fullWidth
              />

              <ControlledTextInput
                name="breed"
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.breed" })}
                onChange={(value: string) => setFieldValue("breed", value)}
              />

              <Dropdown
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.sex" })}
                labelPosition="top"
                items={sexOptions}
                onSelectionChange={key => setFieldValue("sex", key)}
                selectedKey={values.sex}
                fullWidth
              />

              <ControlledTextInput
                name="dateOfBirth"
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.dateOfBirth" })}
                caption={partnerConfig(platform, featureFlags as any).entities.patients.dateOfBirth.format}
                onChange={(value: string) => setFieldValue("dateOfBirth", value)}
              />

              <Dropdown
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.insuranceCompany" })}
                labelPosition="top"
                items={insuranceCompanyOptions}
                onSelectionChange={key => setFieldValue("insuranceCompany", key)}
                selectedKey={values.insuranceCompany}
                fullWidth
              />

              <ControlledTextInput
                name="insuranceNumber"
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.insuranceNumber" })}
                onChange={(value: string) => setFieldValue("insuranceNumber", value)}
                error={error?.show && error?.api === "addPatientProfile" ? error.message : undefined}
              />
            </div>

            <div className={styles.buttonContainer}>
              <Button variant="secondary" color="primary" onPress={close} small fullWidth>
                {intl.formatMessage({ id: "btn.cancel" })}
              </Button>
              <Button variant="primary" color="primary" type="submit" small fullWidth>
                {intl.formatMessage({ id: "btn.add" })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddPet
