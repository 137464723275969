import { DiagnosisCode } from "./SelectCodes"

const LOCAL_STORAGE_KEY = "WEBDOC_FAVORITE_CODES"

export const saveToFavorites = (code: DiagnosisCode): DiagnosisCode[] => {
  const existingFavorites = localStorage.getItem(LOCAL_STORAGE_KEY)

  let newValue = []

  if (existingFavorites) {
    const storageValue = JSON.parse(existingFavorites)
    newValue = [...storageValue, code]
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue))
  } else {
    newValue = [code]
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue))
  }

  return newValue
}

export const removeFromFavorites = (code: DiagnosisCode): DiagnosisCode[] => {
  const existingFavorites = localStorage.getItem(LOCAL_STORAGE_KEY)
  const storageValue: DiagnosisCode[] = JSON.parse(existingFavorites!)

  const newValue = storageValue.filter(c => c.value !== code.value)

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue))

  return newValue
}

export const retrieveFromLocalStorage = (): DiagnosisCode[] => {
  const fromStorage = localStorage.getItem(LOCAL_STORAGE_KEY) || "[]"
  return JSON.parse(fromStorage)
}
