import { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"

import { ICONS, SidebarMenu } from "@doktor-se/bones-ui"
import { BookingType, Role } from "@doktor-se/bones-ui/dist/web-shared/types"

import { logout } from "api"
import { featureFlags, showHelpPage, showSearchPage } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setInboxNotification } from "reducers/app"
import { selectMeetingKinds } from "reducers/booking"
import { selectConversations } from "reducers/conversations/conversations.reducer"

import LogoutDialog from "./components/LogoutDialog"
import CreateConversation from "components/CreateConversation/CreateConversation"

import styles from "./Navigation.module.scss"

interface HasAccessToBookingsArgs {
  roles: Role[]
  bookingTypes: BookingType[]
}

const hasAccessToBookings = ({ roles, bookingTypes }: HasAccessToBookingsArgs): boolean => {
  if (roles.some(role => role.startsWith("booking_coordinator"))) {
    return true
  }

  return Boolean(bookingTypes?.find(bookingType => bookingType.metadata && roles.includes(bookingType.metadata.role)))
}

const Navigation = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const inboxNotification = useAppSelector(state => state.app.inboxNotification)
  const activeInboxTab = useAppSelector(state => state.app.activeInboxTab)
  const isInboxPanelOpen = useAppSelector(state => state.app.isInboxPanelOpen)
  const { active, snoozed } = useAppSelector(selectConversations)
  const { defaultRole, roles } = useAppSelector(state => state.auth)
  const { bookingTypes } = useAppSelector(selectMeetingKinds)
  const [confirm, setConfirm] = useState(false)
  const [toggleAddConv, setToggleAddConv] = useState(false)
  const beamerRef = useRef<HTMLLIElement>(null)

  const showBulkMessagesTab = useMemo(() => {
    if (featureFlags.has("bulk_messages")) {
      if (roles.includes("cherry_pick")) {
        return true
      }
    }
    return false
  }, [roles])

  useEffect(() => {
    if (featureFlags.has("beamer")) {
      beamerRef.current?.classList.add("beamerTrigger")
    }
  }, [])

  const changeTab = (path: string) => {
    navigate(path)
    if (path === "/" && activeInboxTab === "inbox" && isInboxPanelOpen) dispatch(setInboxNotification(undefined))
  }

  return (
    <div className={styles.container}>
      <SidebarMenu title={intl.formatMessage({ id: "login.title" })}>
        <SidebarMenu.SubNav>
          <SidebarMenu.MenuItem
            label={intl.formatMessage({ id: "label.inbox" })}
            Icon={ICONS.Inbox}
            onClick={() => changeTab("/")}
            isActive={location.pathname === "/"}
            showNotification={inboxNotification}
          />
          {featureFlags.has("booking") && hasAccessToBookings({ roles, bookingTypes }) && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.booking" })}
              Icon={ICONS.CalendarToday}
              onClick={() => changeTab("/booking")}
              isActive={location.pathname === "/booking"}
            />
          )}
          {!!showBulkMessagesTab && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "bulk_messages.header" })}
              Icon={ICONS.Mail}
              onClick={() => changeTab("/bulk")}
              isActive={location.pathname === "/bulk"}
            />
          )}
        </SidebarMenu.SubNav>

        <SidebarMenu.SubNav>
          {featureFlags.has("create_conversation") && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.create.conversation" })}
              Icon={ICONS.Add}
              onClick={() => setToggleAddConv(!toggleAddConv)}
              isActive={toggleAddConv}
            />
          )}
          {showSearchPage(defaultRole) && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.search" })}
              Icon={ICONS.Search}
              onClick={() => changeTab("/search")}
              isActive={location.pathname === "/search"}
            />
          )}
          {featureFlags.has("sip") && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.dial" })}
              Icon={ICONS.Dialpad}
              onClick={() => changeTab("/dial")}
              isActive={location.pathname === "/dial"}
            />
          )}
          {featureFlags.has("phrases") && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "phrases.toolbar.phrases.label" })}
              Icon={ICONS.TextSnippet}
              onClick={() => changeTab("/phrases")}
              isActive={location.pathname === "/phrases"}
            />
          )}
          <SidebarMenu.MenuItem
            label={intl.formatMessage({ id: "label.settings" })}
            Icon={ICONS.Settings}
            onClick={() => changeTab("/settings")}
            isActive={location.pathname === "/settings"}
          />
          {showHelpPage(defaultRole) && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.help" })}
              Icon={ICONS.Info}
              onClick={() => changeTab("/help")}
              isActive={location.pathname === "/help"}
            />
          )}
          {featureFlags.has("beamer") && (
            <SidebarMenu.MenuItem
              menuItemRef={beamerRef}
              label={intl.formatMessage({ id: "label.news" })}
              Icon={ICONS.StarOutline}
            />
          )}
          <SidebarMenu.MenuItem
            label={intl.formatMessage({ id: "label.logout" })}
            Icon={ICONS.Login}
            onClick={() => setConfirm(true)}
          />
        </SidebarMenu.SubNav>
      </SidebarMenu>

      {featureFlags.has("create_conversation") && (
        <CreateConversation show={toggleAddConv} hide={() => setToggleAddConv(false)} />
      )}

      <LogoutDialog
        isOpen={confirm}
        logout={() => dispatch(logout())}
        onClose={() => setConfirm(false)}
        assigned={active.length}
        snoozed={snoozed.length}
      />
    </div>
  )
}

export default Navigation
