import { useIntl } from "react-intl"

import classNames from "classnames"

import { ICONS, StaffBodyTextXS, StaffLabelTextS } from "@doktor-se/bones-ui"
import { useTimeToNow } from "@doktor-se/bones-ui/dist/web-shared/hooks"

import { featureFlags } from "config"

import ContactMyClinicCounter from "./ContactMyClinicCounter"

import styles from "./ClaimNextConversation.module.scss"

interface ClaimNextConversationProps {
  queueName: string
  conversationCount: number
  queueTime: number | undefined
  isBlocked: boolean
  autoassignTimer?: number
  grabConversation: () => Promise<void>
  showContactMyClinicCounter?: boolean
}

const ClaimNextConversation = ({
  queueName,
  conversationCount,
  queueTime,
  isBlocked,
  autoassignTimer,
  grabConversation,
  showContactMyClinicCounter = false
}: ClaimNextConversationProps) => {
  const intl = useIntl()
  const formattedQueueTime = useTimeToNow(queueTime)
  return (
    <>
      <div className={styles.sectionHeader}>
        <div>
          {queueName &&
            (featureFlags.has("hide_personal_queue_count") ? (
              <StaffLabelTextS as="p">{queueName}</StaffLabelTextS>
            ) : (
              <StaffLabelTextS as="p">
                {intl.formatMessage(
                  {
                    id: "conversations.queue.title"
                  },
                  {
                    name: queueName,
                    length: conversationCount
                  }
                )}
              </StaffLabelTextS>
            ))}
        </div>
        <div>
          <StaffBodyTextXS as="p">
            {intl.formatMessage(
              {
                id: "conversations.queue.time"
              },
              {
                time: formattedQueueTime.date
                  ? intl.formatDate(formattedQueueTime.date, {
                      day: "numeric",
                      month: "short"
                    })
                  : formattedQueueTime.timeLeft || "-"
              }
            )}
          </StaffBodyTextXS>
        </div>
        {showContactMyClinicCounter && <ContactMyClinicCounter />}
      </div>
      {conversationCount > 0 ? (
        <button
          onClick={grabConversation}
          className={classNames(styles.grabConversation, styles.active, { [styles.inactive]: isBlocked })}>
          <ICONS.Add />

          <div className={styles.text}>
            <StaffBodyTextXS>{intl.formatMessage({ id: "conversations.queue.next" })}</StaffBodyTextXS>

            {typeof autoassignTimer === "number" && autoassignTimer > 0 && (
              <StaffBodyTextXS>
                {intl.formatMessage({ id: "conversations.autoassign.time" }, { time: autoassignTimer })}
              </StaffBodyTextXS>
            )}
          </div>
        </button>
      ) : (
        <button className={styles.grabConversation}>
          <StaffBodyTextXS>{intl.formatMessage({ id: "conversations.queue.empty" })}</StaffBodyTextXS>
        </button>
      )}
    </>
  )
}

export default ClaimNextConversation
