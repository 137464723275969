import { datadogLogs } from "@datadog/browser-logs"
import dayjs from "dayjs"

import { GlobalSnackbarRegion } from "@doktor-se/bones-ui"
import { JournalSection } from "@doktor-se/bones-ui/dist/web-shared/types"
import { datadog } from "@doktor-se/more-rum-please"

import { handleErrors } from "api/error/handler"
import { appApiUrl, webdocFieldsMapping } from "config"
import { apiFetch, externalFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { setWebdocTokens } from "reducers/auth"

export const getWebdocConfig = (): Thunk<Promise<{ clientId: string } | undefined>> => async dispatch => {
  try {
    const response: { clientId: string } = await dispatch(
      apiFetch({
        url: `/webdoc/config`
      })
    )
    return response
  } catch (error: any) {
    dispatch(handleErrors({ error }))
  }
}

export const getWebdocTokens =
  (accessCode: string, redirectUri: string): Thunk =>
  async dispatch => {
    try {
      const response: { accessToken: string; refreshToken: string } = await dispatch(
        apiFetch({
          url: `/webdoc/token`,
          incomingOptions: {
            method: "POST",
            body: JSON.stringify({ access_code: accessCode, redirect_uri: redirectUri })
          }
        })
      )
      dispatch(setWebdocTokens({ accessToken: response.accessToken, refreshToken: response.refreshToken }))
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

export const webdocRefreshToken =
  (refreshToken: string): Thunk<Promise<any | undefined>> =>
  async dispatch => {
    try {
      const response: { accessToken: string } = await dispatch(
        apiFetch({
          url: `/webdoc/refresh_token`,
          incomingOptions: {
            method: "POST",
            body: JSON.stringify({ refresh_token: refreshToken })
          }
        })
      )
      dispatch(setWebdocTokens({ accessToken: response.accessToken, refreshToken: refreshToken }))
      return response.accessToken
    } catch (error: any) {
      if (error.status !== 401 && error.details?.code !== "error.jwt.invalid_token") {
        dispatch(handleErrors({ error }))
      }
      throw error
    }
  }

export const openPatientInWebdoc =
  (pnr: string, accessToken: string, confirmedText: string): Thunk =>
  dispatch => {
    try {
      dispatch(
        apiFetch({
          url: "/webdoc/open_patient",
          incomingOptions: {
            method: "PUT",
            body: JSON.stringify({ personal_number: pnr, access_token: accessToken })
          }
        })
      )

      GlobalSnackbarRegion.queue.add({ text: confirmedText, variant: "information" }, { timeout: 4000 })
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

export const createWebdocFileName = (url: string, pnr?: string) => {
  const baseFilename = url.split("/").pop()
  const dateString = dayjs().format("YYYYMMDD")
  const webdocFilename = `${pnr}_${dateString}_${
    baseFilename?.includes("?") ? baseFilename.split("?")[0] : baseFilename
  }`
  return webdocFilename
}

// handleGlobally determines if error & preloader are shown on whole website
export const webdocUpload =
  (url: string, profile: { pnr?: string; displayName: string }, handleGlobally: boolean = true): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    let fileSizeInMB: number
    return await dispatch(externalFetch(url, { ...(handleGlobally && { loader: "WEBDOC" }), cache: "reload" }))
      .then((response: any) => response.blob())
      .then(async (imgData: Blob) => {
        fileSizeInMB = Number(imgData.size) / (1024 * 1024)

        const data = new FormData()
        const webdocFilename = createWebdocFileName(url, profile.pnr)
        data.append("file", imgData, webdocFilename)
        data.append("documentTypeId", "2")
        profile.pnr && data.append("personalNumber", profile.pnr)
        try {
          datadog.addError({
            anonymizedFilename: webdocFilename.slice(0, 4) + webdocFilename.split("_").slice(1).join("_"),
            url
          })
        } catch {}

        await dispatch(
          externalFetch(`${appApiUrl}/webdoc/uploadV2`, {
            method: "POST",
            body: data,
            ...(handleGlobally && { loader: "WEBDOC" }),
            headers: {
              Authorization: getState?.().auth.accessToken
            }
          })
        )

        datadogLogs.logger.info("File uploaded to webdoc", { fileSize: `${fileSizeInMB} MB` })
      })
      .catch((error: any) => {
        datadogLogs.logger.info("File failed to upload to webdoc", { fileSize: `${fileSizeInMB} MB` })

        if (error.status === 413)
          dispatch(
            handleErrors({
              error,
              customMessage: "webdoc.file.size.exceeded.error",
              customContext: `File size: ${fileSizeInMB} MB`
            })
          )
        else if (handleGlobally) dispatch(handleErrors({ error, customMessage: "webdoc.upload.error" }))
        throw error
      })
  }

export const sendToWebdocCall =
  (sections: JournalSection[], staffPnr?: string, patientPnr?: string): Thunk<Promise<string>> =>
  async dispatch => {
    try {
      const result = await dispatch(
        apiFetch(`/webdoc/journal`, {
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify({
            staff_pnr: staffPnr,
            patient_pnr: patientPnr,
            sections: sections,
            webdocFieldsMapping: webdocFieldsMapping
          }) // webdoc id should be in sections
        })
      )
      GlobalSnackbarRegion.queue.add({ text: "Sent to Webdoc", variant: "success" }, { timeout: 4000 })
      return result.journalNoteId
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }
