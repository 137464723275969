import { useIntl } from "react-intl"

import { Button, ICONS, StaffHeading4, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { Avatar, InitialsBubble, ProfileBubble, SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic, featureFlags } from "config"

import { Selected } from "./CreateConversation"

import styles from "./ConfirmStep.module.scss"

interface ConfirmStepProps {
  loader: boolean
  confirmed: boolean
  hide: () => void
  confirm: () => void
  setSelected: (selected: Selected) => void
  selected: Selected
}

const ConfirmStep = ({ hide, confirm, loader, confirmed, setSelected, selected }: ConfirmStepProps) => {
  const intl = useIntl()

  const categorySymptomBubbleStyle = (category: Category) => {
    if (!!category.metadata.conversationMetaInit?.premiumHealth) {
      return styles.premiumHealth
    }
    if (!!category.metadata.conversationMetaInit?.revisit) {
      return styles.revisit
    }
    return undefined
  }

  return (
    <div className={styles.confirmWrapper}>
      {!loader && !confirmed && (
        <div className={styles.selectedContainer}>
          <div>
            <div>
              <StaffLabelTextXS as="h3" margin={{ bottom: "8px" }}>
                {intl.formatMessage({ id: "search.new.label.patient" })}
              </StaffLabelTextXS>
              <div className={styles.selected}>
                <Avatar />
                <StaffLabelTextXS as="p" className={styles.label}>
                  {selected.patient?.id === "draft" || !selected.patient?.displayName
                    ? selected.patient?.searchedValue
                    : selected.patient?.displayName}
                </StaffLabelTextXS>
                <button
                  className={styles.iconEditRight}
                  onClick={() => setSelected({ ...selected, patient: undefined })}>
                  <ICONS.EditPen />
                </button>
              </div>
            </div>

            {selected.profile && (
              <div>
                <StaffLabelTextXS as="h3" margin={{ bottom: "8px" }}>
                  {intl.formatMessage({ id: "search.new.label.profile" })}
                </StaffLabelTextXS>
                <div className={styles.selected}>
                  {!featureFlags.has("profile_icon") && <InitialsBubble letter={selected.profile.name.charAt(0)} />}

                  {featureFlags.has("profile_icon") && (
                    <ProfileBubble
                      icon={selected.profile.data.icon}
                      letter={selected.profile.name.charAt(0)}
                      appStatic={appStatic}
                    />
                  )}

                  <StaffLabelTextXS as="p" className={styles.label}>
                    {selected.profile.name}
                  </StaffLabelTextXS>
                  <button
                    className={styles.iconEditRight}
                    onClick={() => setSelected({ ...selected, profile: undefined })}>
                    <ICONS.EditPen />
                  </button>
                </div>
              </div>
            )}

            <div>
              <StaffLabelTextXS as="h3" margin={{ bottom: "8px" }}>
                {intl.formatMessage({ id: "search.new.label.category" })}
              </StaffLabelTextXS>
              <div className={styles.selected}>
                {selected.category && (
                  <SymptomBubble
                    className={categorySymptomBubbleStyle(selected.category)}
                    symptom={selected.category}
                    appStatic={appStatic}
                    cssFilter={categorySymptomBubbleStyle(selected.category) && "brightness(0);"}
                  />
                )}
                <StaffLabelTextXS as="p" className={styles.label}>
                  {selected?.category?.name}
                </StaffLabelTextXS>
                <button
                  className={styles.iconEditRight}
                  onClick={() => setSelected({ ...selected, category: undefined })}>
                  <ICONS.EditPen />
                </button>
              </div>
            </div>

            <div>
              <StaffLabelTextXS as="h3" margin={{ bottom: "8px" }}>
                {intl.formatMessage({ id: "search.new.title.assign" })}
              </StaffLabelTextXS>
              <div className={styles.selected}>
                <Avatar avatarUrl={selected?.staff?.avatarUrl} />
                <StaffLabelTextXS as="p" className={styles.label}>
                  {selected?.staff?.displayName}
                </StaffLabelTextXS>
                <button className={styles.iconEditRight} onClick={() => setSelected({ ...selected, staff: undefined })}>
                  <ICONS.EditPen />
                </button>
              </div>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button variant="secondary" color="primary" onPress={() => hide()} fullWidth>
              {intl.formatMessage({ id: "btn.cancel" })}
            </Button>

            <Button variant="primary" color="primary" onPress={confirm} fullWidth>
              {intl.formatMessage({ id: "btn.create" })}
            </Button>
          </div>
        </div>
      )}

      {!loader && confirmed && (
        <div className={styles.success}>
          <ICONS.CheckCircleFilled height={60} width={60} className={styles.confirmedIcon} />
          <StaffHeading4 as="p" margin={{ top: "10px" }}>
            {intl.formatMessage({ id: "label.done" })}
          </StaffHeading4>
        </div>
      )}
    </div>
  )
}

export default ConfirmStep
