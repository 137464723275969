import React, { useMemo } from "react"
import { useIntl } from "react-intl"

import classnames from "classnames"

import { ICONS, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { IconButton } from "@doktor-se/bones-ui/dist/web-shared/components"

import { assignToOtherStaffEnabled, assignToQueueEnabled, featureFlags, sipRegions } from "config"
import { useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"
import { selectConversation } from "reducers/conversations/conversations.reducer"

import { IconUnsnooze } from "icons"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import { categorySelectors } from "../../reducers/categories"
import ReportBug from "./ReportBug/ReportBug"

import styles from "./TopMenu.module.scss"

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

type Event = React.MouseEvent<HTMLButtonElement, MouseEvent>

const getPos = (e: Event) => {
  const { height } = e.currentTarget.getBoundingClientRect()
  const x = e.clientX - 600
  return { left: x, top: height }
}

interface TopMenuProps {
  authorized: boolean
  toQueue: (offset: Offset) => void
  reassign: (offset: Offset) => void
  snoozeConversation: (offset: Offset) => void
  conversationToInbox: (offset: Offset) => void
  closeConversation: (offset: Offset) => void
  isBooking?: boolean
  startCall: (offset: Offset) => void
  waiting: boolean
  callNumber: (offset: Offset) => void
  revisits: (offset: Offset) => void
}

const TopMenu = ({
  authorized,
  toQueue,
  reassign,
  snoozeConversation,
  conversationToInbox,
  closeConversation,
  isBooking = false,
  startCall,
  waiting,
  callNumber,
  revisits
}: TopMenuProps) => {
  const intl = useIntl()
  const { user, roles } = useAppSelector(state => state.auth)
  const clinics = useAppSelector(clinicSelectors.selectAll)

  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const conversation = useAppSelector(selectConversation)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const inboxPanelOpen = useAppSelector(state => state.app.isInboxPanelOpen)
  const category = conversation?.categoryId ? categories[conversation.categoryId] : undefined
  const isUpcomingBooking = conversation?.metadata?.isUpcomingBooking || conversation?.state === "booked"
  const hasAssignToOtherStaff = !isUpcomingBooking && assignToOtherStaffEnabled(defaultRole)
  const hasAssignToQueue = !isUpcomingBooking && assignToQueueEnabled(defaultRole)
  const snoozeConversationFeature = waiting ? !isBooking : true
  const closeConversationFeature = !isBooking || !waiting
  const showAppCallButton = useMemo(() => {
    const webAppPlatform = conversation?.platforms?.find(p => p.type === "web")
    let approvedWebAppPlatform = false

    if (!!webAppPlatform) {
      const allowComputerCall =
        !webAppPlatform.deviceType &&
        !!webAppPlatform.browser &&
        /Firefox|Chrome|Edge|IE|Opera|Safari/i.test(webAppPlatform.browser)

      const allowIOSCall =
        webAppPlatform.deviceType === "mobile" &&
        !!webAppPlatform?.os &&
        /iOS/i.test(webAppPlatform.os) &&
        !!webAppPlatform.browser &&
        /Safari|Chrome/i.test(webAppPlatform.browser)

      const allowAndroidCall =
        webAppPlatform.deviceType === "mobile" &&
        !!webAppPlatform?.os &&
        /Android/i.test(webAppPlatform.os) &&
        !!webAppPlatform.browser &&
        /Firefox|Chrome/i.test(webAppPlatform.browser)

      approvedWebAppPlatform = allowComputerCall || allowIOSCall || allowAndroidCall
    }

    return (
      !waiting &&
      !conversation?.metadata?.noInappCalls &&
      (!webAppPlatform || (webAppPlatform && approvedWebAppPlatform))
    )
  }, [conversation, waiting])

  const showSipCallButton = !waiting && featureFlags.has("sip") && sipRegions.has(conversation?.metadata?.region || "")
  const showRevisitsButton = useMemo(() => {
    const disabledRoles = ["external", "external_nurse"]
    const isPremiumHealth = category?.metadata.conversationMetaInit?.premiumHealth
    return featureFlags.has("revisits") && !isPremiumHealth && !roles.some(role => disabledRoles.includes(role))
  }, [category, roles])

  const userDetails = useMemo(() => {
    const parts = [user?.displayName]

    if (defaultRole) {
      parts.push(capitalize(defaultRole))
    }

    if (user?.data?.clinic) {
      parts.push(clinics.find(c => c.id === user?.data?.clinic)?.name)
    }

    return parts.join(" • ")
  }, [clinics, defaultRole, user?.data?.clinic, user?.displayName])

  return (
    <>
      {authorized && conversation?.id && (
        <div className={classnames(styles.container, { [styles.containerInboxOpen]: inboxPanelOpen })}>
          <div className={classnames(styles.userDetails, { [styles.userDetailsInboxOpen]: inboxPanelOpen })}>
            <StaffLabelTextXS>{userDetails}</StaffLabelTextXS>
          </div>
          <div className={classnames(styles.actions, { [styles.actionsInboxOpen]: inboxPanelOpen })}>
            {showAppCallButton && (
              <IconButton
                onClick={(e: Event) => startCall(getPos(e))}
                Icon={ICONS.PhoneiPhone}
                label={intl.formatMessage({ id: "label.call.app" })}
                labelSize={14}
              />
            )}

            {showSipCallButton && (
              <IconButton
                onClick={(e: Event) => callNumber(getPos(e))}
                Icon={ICONS.Dialpad}
                label={intl.formatMessage({ id: "label.dial" })}
                labelSize={14}
              />
            )}

            {snoozeConversationFeature && (
              <IconButton
                onClick={(e: Event) => snoozeConversation(getPos(e))}
                Icon={ICONS.Snooze}
                label={intl.formatMessage({ id: "label.snooze" })}
                labelSize={14}
              />
            )}

            {waiting && (
              <IconButton
                onClick={(e: Event) => conversationToInbox(getPos(e))}
                Icon={IconUnsnooze}
                label={intl.formatMessage({ id: "action.send.inbox" })}
                labelSize={14}
              />
            )}

            {hasAssignToOtherStaff && (
              <IconButton
                onClick={(e: Event) => reassign(getPos(e))}
                Icon={ICONS.ReplayMirrored}
                label={intl.formatMessage({ id: "label.reassign" })}
                labelSize={14}
              />
            )}
            {hasAssignToQueue && (
              <IconButton
                onClick={(e: Event) => toQueue(getPos(e))}
                Icon={ICONS.Replay}
                label={intl.formatMessage({ id: "label.to.queue" })}
                labelSize={14}
              />
            )}
            {showRevisitsButton && (
              <IconButton
                onClick={(e: Event) => revisits(getPos(e))}
                Icon={ICONS.CalendarAdd}
                label={intl.formatMessage({ id: "revisits.title" })}
                labelSize={14}
              />
            )}
            {closeConversationFeature && (
              <IconButton
                onClick={(e: Event) => closeConversation(getPos(e))}
                Icon={ICONS.CheckCircleOutline}
                label={intl.formatMessage({ id: "label.close" })}
                labelSize={14}
              />
            )}

            <div className={styles.verticalDivider}></div>
            <ReportBug conversationId={conversation.id} buttonType="icon" />
          </div>
        </div>
      )}
    </>
  )
}

export default TopMenu
