import { useIntl } from "react-intl"

import PhrasesContainer, {
  PhrasesContainerProps
} from "@doktor-se/bones-ui/dist/web-shared/components/phrases/PhrasesContainer"

import { featureFlags, phrasesLanguages } from "config"
import { useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"

import { feedTexts } from "pages/dashboard/utils"

interface PhrasesContainerWrapperProps
  extends Pick<PhrasesContainerProps, "isPage" | "insertPhraseText" | "controlsVariant"> {}

const PhrasesContainerWrapper = ({ isPage, insertPhraseText, controlsVariant }: PhrasesContainerWrapperProps) => {
  const phraseData = useAppSelector(state => state.phrases.phrases)
  const user = useAppSelector(state => state.auth.user)
  const clinics = useAppSelector(clinicSelectors.selectAll)
  const intl = useIntl()
  const { phrases } = feedTexts(intl)

  const showFilterDropdown = featureFlags.has("phrases_filter_dropdown")
  const staffClinicId = !!user?.data?.clinic
  const staffClinic = clinics.find(c => c.id === user?.data?.clinic)?.name

  if (!phrasesLanguages || !phraseData) return null

  return (
    <PhrasesContainer
      variableOpts={{
        staffFirstName: user?.firstname,
        staffLastName: user?.lastname,
        staffDisplayName: user?.displayName,
        title: user?.title,
        staffGender: user?.data?.sex,
        staffClinic
      }}
      insertPhraseText={insertPhraseText}
      dataList={phraseData}
      isPage={isPage}
      texts={phrases}
      emptySearchText={intl.formatMessage({ id: "phrases.search.empty" })}
      copyConfirmText={intl.formatMessage({ id: "copied.confirmation" })}
      phrasesLanguages={phrasesLanguages}
      showFilterDropdown={showFilterDropdown}
      initialFilterValue={staffClinicId ? "physical" : "digital"}
      showHeader
      controlsVariant={controlsVariant}
    />
  )
}

export default PhrasesContainerWrapper
