import { useMemo } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { ICONS, StaffBodyTextXS, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { Avatar, SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category, Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic, assignToOtherStaffEnabled, assignToQueueEnabled, reopenConversationEnabled } from "config"
import { useAppSelector } from "lib/hooks"
import { SearchPageConversation } from "types"

import { StateText } from "./components/StateText"

import ConversationControls from "../ConversationControls/ConversationControls"

import styles from "./ConversationRow.module.scss"

export interface ConversationRowProps {
  conversation: SearchPageConversation
  assignedStaff?: Staff
  assigned: boolean
  selectedConversationId?: string
  setConversation: (conversationId: string) => void
  reopen: boolean
  showReopen: () => void
  closeControl: (refetch?: boolean) => void
  reopenConversation: (staffId: string) => void
  showClose: () => void
  close: boolean
  showToQueue: () => void
  toQueue: boolean
  showReassign: () => void
  reassign: boolean
  category?: Category
  showCategory: boolean
}

const ConversationRow = ({
  conversation,
  assignedStaff,
  assigned,
  selectedConversationId,
  setConversation,
  reopen,
  showReopen,
  closeControl,
  reopenConversation,
  showClose,
  close,
  showToQueue,
  toQueue,
  showReassign,
  reassign,
  category,
  showCategory
}: ConversationRowProps) => {
  const intl = useIntl()

  const defaultRole = useAppSelector(state => state.auth.defaultRole)

  const features = useMemo(
    () => ({
      hasAssignToOtherStaff: assignToOtherStaffEnabled(defaultRole),
      hasAssignToQueue: assignToQueueEnabled(defaultRole),
      hasReopen: reopenConversationEnabled(defaultRole)
    }),
    [defaultRole]
  )

  const rowIsClickable: boolean = useMemo(() => {
    const openAndClickable =
      conversation.state === "opened" && (features.hasAssignToOtherStaff || !!conversation.assignedStaffId)
    const closedAndClickable = conversation.state === "closed" && features.hasReopen

    const isNotBooking = !conversation?.metadata?.isUpcomingBooking && conversation.state !== "booked"

    return (openAndClickable || closedAndClickable) && isNotBooking
  }, [
    conversation.state,
    conversation.assignedStaffId,
    conversation?.metadata?.isUpcomingBooking,
    features.hasAssignToOtherStaff,
    features.hasReopen
  ])

  const isPremiumHealth = useMemo(() => {
    return category && category.metadata.conversationMetaInit?.premiumHealth
  }, [category])

  const isRevisit = useMemo(() => {
    return category && category.metadata.conversationMetaInit?.revisit
  }, [category])

  const categorySymptomBubbleStyle = useMemo(() => {
    if (isPremiumHealth) return styles.premiumHealth
    if (isRevisit) return styles.revisit
    return undefined
  }, [isPremiumHealth, isRevisit])

  const profile = conversation.account?.profiles?.find(profile => profile.id === conversation.profileId)

  return (
    <div className={styles.container}>
      <button
        className={classNames(styles.conversation, {
          [styles.active]: selectedConversationId === conversation.id,
          [styles.showCategory]: showCategory,
          [styles.rowIsClickable]: rowIsClickable
        })}
        onClick={() => rowIsClickable && setConversation(conversation.id)}>
        <div className={styles.leftContainer}>
          {showCategory && (
            <div className={styles.bubbleContainer}>
              <SymptomBubble
                className={categorySymptomBubbleStyle}
                symptom={category}
                appStatic={appStatic}
                cssFilter={"brightness(0);"}
              />
            </div>
          )}

          {profile?.type === "child" && <ICONS.Child className={styles.childIcon} />}

          <StaffLabelTextXS as="h3" margin={{ right: "5px" }}>
            {`${
              (profile?.type === "child" && profile?.name) ||
              conversation.account?.displayName ||
              intl.formatMessage({ id: "label.unknown" })
            }.`}
          </StaffLabelTextXS>
          <StaffBodyTextXS margin={{ right: "5px" }}>
            {intl.formatMessage(
              {
                id: "search.created"
              },
              {
                date: intl.formatDate(conversation.created, { day: "2-digit", month: "2-digit", year: "numeric" })
              }
            )}
          </StaffBodyTextXS>
          {assigned && (
            <StaffBodyTextXS margin={{ right: "5px" }}>
              {intl.formatMessage(
                {
                  id: "search.assigned"
                },
                {
                  staff: assignedStaff ? assignedStaff.displayName : intl.formatMessage({ id: "label.unknown" })
                }
              )}
            </StaffBodyTextXS>
          )}
        </div>

        <div className={styles.rightContainer}>
          <StateText conversation={conversation} />

          <div className={classNames(styles.staffImgContainer, { [styles.hidden]: !assignedStaff })}>
            <Avatar avatarUrl={assignedStaff?.avatarUrl} online={assignedStaff?.online} />
          </div>
        </div>
      </button>

      {selectedConversationId === conversation.id && (
        <ConversationControls
          conversation={conversation}
          reopen={reopen}
          showReopen={showReopen}
          closeControl={closeControl}
          reopenConversation={reopenConversation}
          showClose={showClose}
          close={close}
          showToQueue={showToQueue}
          toQueue={toQueue}
          reassign={reassign}
          showReassign={showReassign}
          features={features}
        />
      )}
    </div>
  )
}

export default ConversationRow
