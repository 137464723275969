import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import classnames from "classnames"

import {
  Button,
  Dropdown,
  ICONS,
  StaffBodyTextM,
  StaffBodyTextS,
  StaffLabelTextS,
  StaffLabelTextXS
} from "@doktor-se/bones-ui"
import { OptionProps } from "@doktor-se/bones-ui/dist/components/Dropdown/Dropdown"
import { AssignedConversation, Clinic, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { useAppSelector } from "lib/hooks"
import { getConversationClinic, metersToKmString } from "lib/utils"
import { clinicSelectors } from "reducers/clinics"
import { queueSelectors } from "reducers/queue/queue.reducer"
import { SearchPageConversation } from "types"

import { ConfirmParams } from "../PhysicalToDigitalDialog"

import styles from "./ToPhysicalQueue.module.scss"

interface ToPhysicalQueueProps {
  conversation: AssignedConversation | SearchPageConversation
  selectedQueue: Queue | undefined
  setSelectedQueue: (queue: Queue | undefined) => void
  onSend: (params: ConfirmParams) => void
}

const ToPhysicalQueue = ({ conversation, selectedQueue, setSelectedQueue, onSend }: ToPhysicalQueueProps) => {
  const intl = useIntl()
  const appLanguage = useAppSelector(state => state.app.language)
  const queues = useAppSelector(queueSelectors.selectAll)
  const patientClinics = useAppSelector(state => state.clinics.patientClinics)
  const clinics = useAppSelector(clinicSelectors.selectAll)

  const clinic: Clinic | undefined = useMemo(() => {
    const metadata = {
      platform: conversation.metadata,
      customer: conversation.customerMetadata
    }
    return getConversationClinic({ clinics, metadata })
  }, [clinics, conversation])

  const [selectedClinic, setSelectedClinic] = useState<Clinic | undefined>(clinic)
  const [availableClinicQueues, setAvailableClinicQueues] = useState<OptionProps[]>()

  const selectPatientClinic = useCallback(
    (clinicId: string) => {
      const selected = clinics.find(c => c.id === clinicId)
      setSelectedClinic(selected)
      const clinicQueues = queues.filter(q => selected?.supported_queue_ids?.includes(q.id))
      if (clinicQueues.length === 1) {
        setSelectedQueue(clinicQueues[0])
      } else {
        setSelectedQueue(undefined)
      }
      setAvailableClinicQueues(clinicQueues.map(c => ({ id: c.id, name: c.translations[appLanguage] })))
    },
    [appLanguage, clinics, queues, setSelectedQueue]
  )

  useEffect(() => {
    if (clinic) {
      selectPatientClinic(clinic?.id)
    }
  }, [clinic, selectPatientClinic])

  const selectClinicQueue = (queueId: string) => {
    const selected = queues?.find(q => q.id === queueId)
    if (selected) {
      setSelectedQueue(selected)
    }
  }

  const sendToQueue = () => {
    onSend({ clinicId: selectedClinic?.id })
  }

  return (
    <>
      {patientClinics?.map((c, index) => (
        <div
          role="button"
          tabIndex={index}
          onKeyDown={event => {
            if (event.key === "Enter") {
              selectPatientClinic(c.id)
            }
          }}
          key={c.id}
          onClick={() => selectPatientClinic(c.id)}
          className={classnames(styles.clinicButton, {
            [styles.clinicButtonSelected]: selectedClinic?.id === c.id
          })}>
          <div className={styles.clinicButtonIcon}>
            <ICONS.ClinicMedium stroke="white" />
          </div>
          <div className={styles.clinicDetailsWrapper}>
            <div className={styles.clinicDetails}>
              <div className={styles.clinicDetailsName}>
                <StaffLabelTextS className={styles.clinicButtonName}>{c.name}</StaffLabelTextS>
                <StaffBodyTextS>{c.address}</StaffBodyTextS>
              </div>
              <ICONS.ChevronRight />
            </div>
            <div className={styles.clinicDistance}>
              <StaffLabelTextXS className={styles.clinicDistanceText}>{metersToKmString(c.distance)}</StaffLabelTextXS>
            </div>
          </div>
          {selectedClinic?.id === c.id && (
            <div className={styles.clinicQueueWrapper}>
              {availableClinicQueues && !!availableClinicQueues.length && (
                <div className={styles.clinicQueueWrapper}>
                  <Dropdown
                    label="Queue"
                    onSelectionChange={selectClinicQueue}
                    defaultSelectedKey={selectedQueue?.id}
                    placeholder={intl.formatMessage({ id: "label.to_queue.clinic.select" })}
                    fullWidth
                    items={availableClinicQueues}
                    variant="onBackground"
                  />
                </div>
              )}
              {(!availableClinicQueues || !availableClinicQueues.length) && (
                <StaffBodyTextM style={{ marginBottom: "16px" }}>
                  {intl.formatMessage({ id: "conversation.to_queue.no_available_clinic_queue" })}
                </StaffBodyTextM>
              )}
            </div>
          )}
          {selectedClinic?.id === c.id && selectedQueue && (
            <div className={styles.buttonContainer}>
              <Button isDisabled={!selectedQueue} variant="primary" fullWidth color="primary" onPress={sendToQueue}>
                {intl.formatMessage({ id: "label.send" })}
              </Button>
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default ToPhysicalQueue
