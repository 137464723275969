import PageContainer from "components/PageContainer/PageContainer"
import PhrasesContainerWrapper from "components/phrases/PhrasesContainerWrapper"

import styles from "./PhrasesPage.module.scss"

const PhrasesPage = () => {
  return (
    <PageContainer>
      <div className={styles.phrasesContainer}>
        <PhrasesContainerWrapper isPage={true} />
      </div>
    </PageContainer>
  )
}

export default PhrasesPage
