import { useIntl } from "react-intl"

import { ICONS, StaffBodyTextXS, StaffLabelTextXS } from "@doktor-se/bones-ui"

import styles from "./HideVideo.module.scss"

const HideVideo = () => {
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div>
        <ICONS.VisibilityOff className={styles.icon} />
        <StaffLabelTextXS margin={"var(--size-100) 0"}>
          {intl.formatMessage({ id: "app.call.hide.patient.video.title" })}
        </StaffLabelTextXS>
        <StaffBodyTextXS>{intl.formatMessage({ id: "app.call.hide.patient.video.body" })}</StaffBodyTextXS>
      </div>
    </div>
  )
}

export default HideVideo
