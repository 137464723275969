import classNames from "classnames"

import { StaffLabelTextXS } from "@doktor-se/bones-ui"

import styles from "./PatientAvatar.module.scss"

interface PatientAvatarProps {
  size: "small" | "big"
  letter?: string
  imgName?: string
}

// TODO: Move into bones-ui
const PatientAvatar = ({ size, letter, imgName }: PatientAvatarProps): JSX.Element => {
  return (
    <div className={classNames(styles.container, styles[size], letter && styles[letter.toLowerCase()])}>
      {letter && !imgName && <StaffLabelTextXS as="p">{letter}</StaffLabelTextXS>}
      {imgName && <img className={styles[size]} src={`${import.meta.env.VITE_APP_APP_STATIC}/${imgName}.png`} alt="" />}
    </div>
  )
}

export default PatientAvatar
